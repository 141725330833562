import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';

import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import { ethers, formatEther } from 'ethers';

import { BrowserProvider, Contract, formatUnits, WebSocketProvider, parseUnits, parseEther } from 'ethers'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import BigNumber from 'bignumber.js';
import shillitary from '../Images/Shillitary.png'
import Popup from 'reactjs-popup';

import Beavers from '../artifacts/contracts/LionBeaver.sol/LionBeaver.json';
import Token from '../artifacts/contracts/LionToken.sol/LionToken.json';
import Swal from 'sweetalert2';
import '../Styles/Home.css';
import one from '../Images/1.PNG'
import two from '../Images/2.PNG' 
import three from '../Images/3.PNG'  
import four from '../Images/4.PNG' 
import five from '../Images/5.PNG' 
import six from '../Images/6.PNG' 
import { FaBars, FaTimes } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles





const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};


const beaversContractAddress = '0x75Fbcecc21D310B3A4aE2aEBCaB4D59bb020b355';


const NFTHead = () => {

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [showInfo, setShowInfo] = useState();
  const [showUserName, setUserName] = useState();
  const [showUserTg, setUserTg] = useState();
  const [showUserTwitter, setUserTwitter] = useState();
  const [showUserRank, setUserRank] = useState();
  const [showUserPoints, setUserPoints] = useState();
  const [showUserRaided, setUserRaided] = useState();
  const [showUserTotalEth, setUserTotalEth] = useState();
  const [showUserTotalDefi, setUserTotalDefi] = useState(); 
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
 

  const [showUserPeth, setUserPendingETH] = useState();
  const [showUserpdefi, setUserPendingDefi] = useState();

  const [showTotalRaid, setShowTotalRaid] = useState();
  const [showTotalUser, setShowTotalUsers] = useState();


  const [infoValue, setInfoValue] = useState('');
  const [showProjectOwnerInfo, setShowProjectOwnerInfo] = useState(false);
  const [showShillitaryInfo, setShowShillitaryInfo] = useState(false);
  const [mainToggleValue, setMainToggleValue] = useState('Project Owner');
  const [mainUserValue, setHandleUserValue] = useState('Project Owner');
  const [tweetsValue, setBuyTweets] = useState("1");
  const [tweetsLength, setTweetsLength] = useState(false);
  const [showRaid, setShowRaid] = useState(true);
  const [showBuyTweets1, setBuyTweets1] = useState(true);
  const [showBuyTweets3, setBuyTweets3] = useState();
  const [showBuyTweets5, setBuyTweets5] = useState();
  const [remaining, setRemianingTweets] = useState();
  const [showImage, setImage] = useState();
  const [showProjectOwner, setShowProjectOwner] = useState(true);
  const [showShillitary, setShowShillitary] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [pointsleaderboardData, setpointsLeaderboardData] = useState([]);



  const [showRaidable1, setRaidable1] = useState();
  const [showRaidable2, setRaidable2] = useState();
  const [showRaidable3, setRaidable3] = useState();
  const [showRaidable4, setRaidable4] = useState();
  const [showRaidable5, setRaidable5] = useState();


  const [showFee1, setFee1] = useState();
  const [showFee3, setFee3] = useState();
  const [showFee5, setFee5] = useState();
  const [showTillRank, setTillRank] = useState();
  const [remmainingTweets, setRemianing] = useState();




  const [rabbitHistory, setRabbitHistoryData] = useState({ rewards: [] });

  const [showID, setID] = useState();
  const [showReset, setReset] = useState();






  const [deposit1, setDeposit1] = useState();
const handleDepositChange1 = (e) => {
  setDeposit1(e.target.value);
};
const [deposit2, setDeposit2] = useState();
const handleDepositChange2 = (e) => {
  setDeposit2(e.target.value);
};
const [deposit3, setDeposit3] = useState();
const handleDepositChange3 = (e) => {
  setDeposit3(e.target.value);
};
const [deposit4, setDeposit4] = useState();
const handleDepositChange4 = (e) => {
  setDeposit4(e.target.value);
};
const [deposit5, setDeposit5] = useState();
const handleDepositChange5 = (e) => {
  setDeposit5(e.target.value);
};



const handleInfo = (value) => {
  if(value === 'info') {
    setInfoValue(value)
    setShowInfo(true)
    setShowProjectOwnerInfo(false)
    setShowShillitaryInfo(false)

  }

  if(value === 'projectOwner') {
    setInfoValue(value)
    setShowInfo(false)
    setShowProjectOwnerInfo(true)
    setShowShillitaryInfo(false)
  }

  if(value === 'Shillitary') {
    setInfoValue(value)
    setShowInfo(false)
    setShowProjectOwnerInfo(false)
    setShowShillitaryInfo(true)

  }
};

const handleTweets = (value) => {
  if(value === "1") {
    setBuyTweets(value)
    setBuyTweets1(true)
    setBuyTweets3(false)
    setBuyTweets5(false)

  }

  if(value === "3") {
    setBuyTweets(value)
    setBuyTweets1(false)
    setBuyTweets3(true)
    setBuyTweets5(false)
  }

  if(value === "5") {
    setBuyTweets(value)
    setBuyTweets1(false)
    setBuyTweets3(false)
    setBuyTweets5(true)

  }
};





const handleMain = (value) => {
  if(value === 'Project Owner') {
    setMainToggleValue(value)
    setShowProjectOwner(true)
    setShowShillitary(false)
    setShowLeaderboard(false)
    setShowInformation(false)
    setShowProfile(false)
   
    console.log('Project Owner')
  }
  if(value === 'Shillitary Team') {
    setMainToggleValue(value)
    setShowProjectOwner(false)
    fetchUserData()
    fetchUserTweets()
    setShowShillitary(true)
    setShowLeaderboard(false)
    setShowInformation(false)
    setShowProfile(false)

    console.log('Shillitary Team')
  }
  if(value === 'Leaderboard') {
    setMainToggleValue(value)
    setShowProjectOwner(false)
    setShowShillitary(false)
    fetchpointsLeaderboardData()
    fetchLeaderboardData()
    setShowLeaderboard(true)
    setShowInformation(false)
    setShowProfile(false)

    console.log('Shillitary Team')
  }
  if(value === 'Information') {
    setMainToggleValue(value)
    setShowProjectOwner(false)
    setShowShillitary(false)
    setShowLeaderboard(false)
    setShowProfile(false)
    setShowInformation(true)

    console.log('Info')
  }

  if(value === 'Profile') {
    setMainToggleValue(value)
    fetchUserData()
    setShowProjectOwner(false)
    setShowShillitary(false)
    setShowLeaderboard(false)
    setShowInformation(false)
    setShowProfile(true)

    console.log('Info')
  }
};





useEffect(() => {
  fetchTweetFees();
  fetchRemianingTweets();
  fetchLeaderboardData()
  fetchpointsLeaderboardData()
  getUserId()
  fetchUserTweets()
  getDailyTimer()
}, [address])



const extractDomain = (url) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname === 'twitter.com' || parsedUrl.hostname === 'x.com';
  } catch (error) {
    return false;
  }
};

const renderTweetOptions = () => {
  const tweetOptions = [
    { value: "1", fee: showFee1, placeholders: ["Tweet Link"], count: 1 },
    { value: "3", fee: showFee3, placeholders: ["Tweet Link", "Tweet Link", "Tweet Link"], count: 3 },
    { value: "5", fee: showFee5, placeholders: ["Tweet Link", "Tweet Link", "Tweet Link", "Tweet Link", "Tweet Link"], count: 5 },
  ];

  const inputValues = [deposit1, deposit2, deposit3, deposit4, deposit5];
  const inputHandlers = [setDeposit1, setDeposit2, setDeposit3, setDeposit4, setDeposit5];

  return tweetOptions.map(({ value, fee, placeholders, count }) => (
    <Box key={value} mt="20px">
      <Text className='cardtxt'>Price: {fee} ETH</Text>

      {/* Only render the input fields for the number of deposits needed (1, 3, or 5) */}
      {placeholders.slice(0, count).map((placeholder, index) => (
        <React.Fragment key={index}>
          <input
            type="text"
            className="form-control"
            onChange={(e) => inputHandlers[index](e.target.value)}
            onBlur={(e) => {
              const inputValue = e.target.value.trim(); // Trim to remove extra spaces
              if (inputValue !== "" && !extractDomain(inputValue)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Suspicious Link Found',
                  text: `Links Must Be From X Or Twitter`,
                });
                inputHandlers[index](''); // Clear the input if invalid
              }
            }}
            value={inputValues[index]}
            placeholder={placeholder}
            style={{ marginBottom: '10px' }}
          />
          <br />
        </React.Fragment>
      ))}

      <Button
        className="toggle-button"
        onClick={() => {
          if (count === 1) {
            Buy1Tweet(deposit1);  // Call Buy1Tweet with deposit1
          } else if (count === 3) {
            Buy3Tweet(deposit1, deposit2, deposit3);  // Call Buy3Tweet with deposit1, deposit2, deposit3
          } else if (count === 5) {
            Buy5Tweet(deposit1, deposit2, deposit3, deposit4, deposit5);  // Call Buy5Tweet with all deposits
          }
        }}
        colorScheme="pink"
        mt="10px"
      >
        {`Buy ${count} Raid${count > 1 ? 's' : ''}`}
      </Button>
    </Box>
  ));
};







const extractUsername = (tweetUrl) => {
  try {
   
    const url = new URL(tweetUrl);

   
    if (url.hostname === 'twitter.com' || url.hostname === 'x.com') {
    const urlParts = tweetUrl.split('/');
    return `@${urlParts[3]}`; 
    } else {
    
      return 'Possibly Malicious URL';
    }
  } catch (error) {
    return 'No Tweet To Raid';
  }
};

const TweetsToRaid = ({ tweets }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const openTweetInNewTab = (tweet) => {
    const webUrl = tweet;

    if (isMobile) {
      window.open(webUrl, '_blank', 'noopener,noreferrer'); // Force opening a new tab in MetaMask browser
    } else {
      window.open(webUrl, '_blank'); // Fallback for desktop
    }
  };

  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="20px" mt="20px">
      <Box className="card-header10">
        <Text className="Heading">TWEETS TO RAID</Text>
      </Box>
      <Box className="card-header10">
        <Text className="baseHead">Remaining Tweets<br></br>{remaining}</Text>
      </Box>
      {tweets.map((tweet, index) => (
        <Box className="card-header1" key={index}>
          <a href="#" onClick={() => openTweetInNewTab(tweet)} className="tweet-link">
            {extractUsername(tweet)}
          </a>
        </Box>
      ))}




      <Box display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
        <Button className="toggle-button" onClick={ConfirmTweets} colorScheme="pink">
          Next Tweets
        </Button>
        <br />
      </Box>
      {tweetsLength && (

<Box display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
 <Button className="toggle-button" onClick={SkipLine} colorScheme="pink">
  Skip To Recent Tweets
 </Button>
 <br />
</Box>
)}
      <Text className="small"> Any pending rewards will be claimed after clicking this button</Text>
    </Grid>
  );
};




async function getUserId() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);


    const soldiers = await contract.totalSupply();
    const totalraided = await contract.totalTweetsRaided();

    setShowTotalRaid(totalraided.toString());
    setShowTotalUsers(soldiers.toString());

    try {
      if (signer) {
        // Fetch the user ID owned by the wallet address
        const userIds = await contract.walletOfOwner(address);

        // Extract the first (and only) ID from the array
        const id = userIds[0].toString();

        console.log("This is the ID:", id);

        // Set the ID in your state or return it
        setID(id);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    console.log("No address found.");
  }
}




async function fetchLeaderboardData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        console.log("Fetching leaderboard data...");

        // Fetch the leaderboard data from the contract
        const [topUsernames, topTweetsRaided] = await contract.getRaidedLeaderboard();

        // Ensure we use BigNumber to handle precision and convert to string for display
        const leaderboardData = topUsernames.map((username, index) => ({
          username: username,
          tweetsRaided: topTweetsRaided[index].toString(),
        }));

        // Set the leaderboard data in your state or return it
        setLeaderboardData(leaderboardData);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setLeaderboardData(["No leaderboard data found."]);
  }
}


async function fetchpointsLeaderboardData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        console.log("Fetching leaderboard data...");

        // Fetch the leaderboard data from the contract
        const [topUsernames, topTweetsRaided] = await contract.getPointsLeaderboard();

        // Ensure we use BigNumber to handle precision and convert to string for display
        const leaderboardData = topUsernames.map((username, index) => ({
          username: username,
          points: topTweetsRaided[index].toString(),
        }));

        // Set the leaderboard data in your state or return it
        setpointsLeaderboardData(leaderboardData);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setpointsLeaderboardData(["No leaderboard data found."]);
  }
}



async function fetchUserData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        console.log("FetchingUSER DATA");

        // Fetching all beaver IDs owned by the wallet
        const user = await contract.dogTag(showID);
        const name =user[1].toString()
        const rank = user[3].toString()
        const points = user[4].toString()
    
        const raided = user[5].toString()

        const totalEth = formatEther(user[6]);  // Convert to Ether string
        const Teth = parseFloat(totalEth).toFixed(4);  // Convert to float and format
        console.log("TETH", Teth);
        
        const totalDefi = formatEther(user[7]);  // Convert to Ether string
        const Tdefi = parseFloat(totalDefi).toFixed(4);  // Convert to float and format
        console.log("TDEFI", Tdefi);


        const pendingETH = await contract.viewPendingEthRewards(showID);
        const pendingETHValue = new BigNumber(pendingETH.toString());
        const peth = parseFloat(pendingETHValue.dividedBy("1e18").toFixed(4)); // Convert Wei to Ether
        console.log("Pending ETH:", peth);
        
        const pendingDeFi = await contract.viewPendingDefiRewards(showID);
        const pendingDeFiValue = new BigNumber(pendingDeFi.toString());
        const pdefi = parseFloat(pendingDeFiValue.dividedBy("1e18").toFixed(4)); // Convert Wei to Ether
        console.log("Pending DeFi:", pdefi);
        
        if(rank === "1"){
          setUserRank("Jeet");
          setImage(one)
        }
        if(rank === "2"){
          setUserRank("Degen");
          setImage(two)
        }
        if(rank === "3"){
          setUserRank("Ape");
          setImage(three)
        }
        if(rank === "4"){
          setUserRank("Chad");
          setImage(four)
        }
        if(rank === "5"){
          setUserRank("Dev");
          setImage(five)
        }
        if(rank === "6"){
          setUserRank("Geek");
          setImage(six)
        }

        const rank2 = await contract.rankTwoPoints();
        const rank3 = await contract.rankThreePoints();
        const rank4 = await contract.rankFourPoints();
        const rank5 = await contract.rankFivePoints();
        const rank6 = await contract.rankSixPoints();

        console.log("Till Rank", rank2)

        if(rank === "1"){
          const Rank = rank2.toString() - points;
          setTillRank(Rank)
          console.log("Till Rank", Rank)
        }

        if(rank === "2"){
          const Rank = rank3.toString() - points;
          setTillRank(Rank)
          console.log("Till Rank", Rank)
        }
        if(rank === "3"){
          const Rank = rank4.toString() - points;
          setTillRank(Rank)
          console.log("Till Rank", Rank)
        }
        if(rank === "4"){
          const Rank = rank5.toString() - points;
          setTillRank(Rank)
          console.log("Till Rank", Rank)
        }
        if(rank === "5"){
          const Rank = rank6.toString() - points;
          setTillRank(Rank)
          console.log("Till Rank", Rank)
        }
        


        setUserName(name);
       
        
        setUserPoints(points);
        setUserRaided(raided);
        setUserTotalEth(Teth);
        setUserTotalDefi(Tdefi);
        setUserPendingDefi(pdefi);
        setUserPendingETH(peth);

      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
  }
}





async function fetchTweetFees() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching all beaver IDs owned by the wallet
        const fee1 = await contract.oneTweetFee();
        setFee1(formatEther(fee1))

        const fee3 = await contract.threeTweetFee();
        setFee3(formatEther(fee3))

        const fee5 = await contract.fiveTweetFee();
        setFee5(formatEther(fee5))
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
  }
}

async function fetchUserTweets() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetch total number of tweets and convert to number
        const Behind = await contract.getTweetsLength();
        const numBehind = Number(Behind); 
        console.log("Behind: " + numBehind);
        
        
        // Fetch DogTag data and extract userPoints (assuming index 5 is `tweetsRaided`)
        const current = await contract.dogTag(showID);
        const userPoints = current[5]; 
        const numUserPoints = Number(userPoints); 
        const toCaughUp = numBehind - numUserPoints;
        setRemianingTweets(toCaughUp);

        console.log(toCaughUp + " number to complete");
        console.log(numUserPoints + " Current User Raided");

        // Check if the user is behind in raiding tweets
        if (numUserPoints < (numBehind - 15)) {
          setTweetsLength(true);
          console.log("Tweets Behind, Please Update");
        }

        // Fetch the raidable tweets based on the showID
        const call = await contract.viewRaidableTweets(showID);

        if (call) {
          // Extract tweets and handle them
          const tweet1 = call[0] ? call[0].toString() : "No Tweet";
          const tweet2 = call[1] ? call[1].toString() : "No Tweet";
          const tweet3 = call[2] ? call[2].toString() : "No Tweet";
          const tweet4 = call[3] ? call[3].toString() : "No Tweet";
          const tweet5 = call[4] ? call[4].toString() : "No Tweet";

          // Set the raidable tweets
          setRaidable1(tweet1);
          setRaidable2(tweet2);
          setRaidable3(tweet3);
          setRaidable4(tweet4);
          setRaidable5(tweet5);
        } else {
          // No raidable tweets found, set default messages
          console.log("No raidable tweets found.");
          setRaidable1("No raidable tweets found.");
          setRaidable2("No raidable tweets found.");
          setRaidable3("No raidable tweets found.");
          setRaidable4("No raidable tweets found.");
          setRaidable5("No raidable tweets found.");
        }
      }
    } catch (err) {
      console.error("Error fetching tweets: ", err);
      // Set default messages on error
      setRaidable1("Error Please Reach Out");
      setRaidable2("Error Please Reach Out");
      setRaidable3("Error Please Reach Out");
      setRaidable4("Error Please Reach Out");
      setRaidable5("Error Please Reach Out");
    }
  } else {
    // No wallet connected, set default messages
    setRaidable1("Wallet Not Connected");
    setRaidable2("Wallet Not Connected");
    setRaidable3("Wallet Not Connected");
    setRaidable4("Wallet Not Connected");
    setRaidable5("Wallet Not Connected");
  }
}






async function fetchRemianingTweets() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching all beaver IDs owned by the wallet
        const limit = await contract.dailyTweetLimit();
        const at = await contract.dailyCounter();
        const remaining = (limit - at).toString();
        setRemianing(remaining);
        console.log("This is remaining tweets",remaining)
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
  }
}





async function getDailyTimer() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        // Fetch the daily timer from the contract (block.timestamp)
        const timestamp = await contract.dailyTimer();
        const add = await contract.testDailyTimer();

        const num = timestamp + add;

        // Convert the timestamp to a number if it's not already
        const timestampNumber = Number(num);

        // Convert the Unix timestamp (in seconds) to a JavaScript Date object
        const dateTime = new Date(timestampNumber * 1000);

        // Format the date and time to the local user's time zone
        const localDateTime = dateTime.toLocaleString();

        console.log("Local Date and Time:", localDateTime);

        // Return or set the local date and time
        setReset(localDateTime);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    console.log("No address found.");
  }
}



const ConfirmTweets = async () => {
  try {
    
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
      const contract = new Contract(beaversContractAddress, Beavers.abi, signer);
   
    
      const transaction = await contract.confirmTweets(showID);


Swal.fire({
icon: 'info',
title: 'Transaction Pending',
text: `Confirming Tweets For ${showUserName}. Please wait...`,
allowOutsideClick: false,
allowEscapeKey: false,
allowEnterKey: false,
showConfirmButton: false,
});
const receipt = await transaction.wait();
console.log('Transaction successful:', receipt);
Swal.close(); // Close the pending alert
Swal.fire({
icon: 'success',
title: 'Transaction Successful',
text: `Logged Tweets for ${showUserName}`,
});
getUserId()
fetchUserTweets()
} catch (error) {
console.error('Transaction failed:', error);

Swal.fire({
icon: 'error',
title: 'Transaction Failed',
text: error.reason || error.message,
});
}
};


const UpgradeRank = async () => {
  try { 
   const ethersProvider = new BrowserProvider(walletProvider)
   const signer = await ethersProvider.getSigner()
   const contract = new Contract(beaversContractAddress, Beavers.abi, signer);
   const transaction = await contract.rankUp(showID)
  
     Swal.fire({
       icon: 'info',
       title: 'Transaction Pending',
       text: 'Claiming New Rank. Please wait...',
       allowOutsideClick: false,
       allowEscapeKey: false,
       allowEnterKey: false,
       showConfirmButton: false,
   });
   const receipt = await transaction.wait();
   console.log('Transaction successful:', receipt);
   Swal.close(); // Close the pending alert
   Swal.fire({
       icon: 'success',
       title: 'Transaction Successful',
       text: 'New Rank Set Successful',
   });
  
   fetchUserData()
 } catch (error) {
   console.error('Transaction failed:', error);

   Swal.fire({
       icon: 'error',
       title: 'Transaction Failed',
       text: error.reason || error.message,
   });
}
};


const SkipLine = async () => {
  try { 
   const ethersProvider = new BrowserProvider(walletProvider)
   const signer = await ethersProvider.getSigner()
   const contract = new Contract(beaversContractAddress, Beavers.abi, signer);
   const transaction = await contract.skipLine(showID)
  
     Swal.fire({
       icon: 'info',
       title: 'Transaction Pending',
       text: 'Skipping Tweets. Please wait...',
       allowOutsideClick: false,
       allowEscapeKey: false,
       allowEnterKey: false,
       showConfirmButton: false,
   });
   const receipt = await transaction.wait();
   console.log('Transaction successful:', receipt);
   Swal.close(); // Close the pending alert
   Swal.fire({
       icon: 'success',
       title: 'Transaction Successful',
       text: 'Skipped Successfully',
   });
   fetchUserTweets() 
   fetchUserData()
 } catch (error) {
   console.error('Transaction failed:', error);

   Swal.fire({
       icon: 'error',
       title: 'Transaction Failed',
       text: error.reason || error.message,
   });
}
};






 const Buy1Tweet = async () => {
       try { 
        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
        const contract = new Contract(beaversContractAddress, Beavers.abi, signer);
        console.log('Deposit1:', deposit1); // Log deposit1 value
    console.log('Fee:', showFee1); // Log fee for 1 tweet
        const transaction = await contract.setOneTweet(deposit1, {
        value: parseEther(showFee1.toString())});
          Swal.fire({
            icon: 'info',
            title: 'Transaction Pending',
            text: 'Setting Up Tweet To Raid. Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'Tweet Set Successful',
        });
        fetchRemianingTweets();
      } catch (error) {
        console.error('Transaction failed:', error);
  
        Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason || error.message,
        });
    }
  };


  const Buy3Tweet = async () => {
    try {
      const ethersProvider = new BrowserProvider(walletProvider)
      const signer = await ethersProvider.getSigner()
        const contract = new Contract(beaversContractAddress, Beavers.abi, signer);
        console.log('Deposit1:', deposit1, 'Deposit2:', deposit2, 'Deposit3:', deposit3);
        console.log('Fee:', showFee3);
        const transaction = await contract.setThreeTweets(deposit1,deposit2,deposit3, {
          value: parseEther(showFee3.toString())
      });
Swal.fire({
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Setting Up Tweet To Raid. Please wait...',
  allowOutsideClick: false,
  allowEscapeKey: false,
  allowEnterKey: false,
  showConfirmButton: false,
});
const receipt = await transaction.wait();
console.log('Transaction successful:', receipt);
Swal.close(); // Close the pending alert
Swal.fire({
  icon: 'success',
  title: 'Transaction Successful',
  text: 'Tweet Set Successful',
});
fetchRemianingTweets();
} catch (error) {
console.error('Transaction failed:', error);

Swal.fire({
  icon: 'error',
  title: 'Transaction Failed',
  text: error.reason || error.message,
});
}
};


const Buy5Tweet = async () => {
  try {
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
      const contract = new Contract(beaversContractAddress, Beavers.abi, signer);
      console.log('Deposit1:', deposit1, 'Deposit2:', deposit2, 'Deposit3:', deposit3, 'Deposit4:', deposit4, 'Deposit5:', deposit5);
    console.log('Fee:', showFee5);
      const transaction = await contract.setFiveTweets(deposit1,deposit2,deposit3,deposit4,deposit5, {
        value:parseEther(showFee5.toString())
    });
Swal.fire({
icon: 'info',
title: 'Transaction Pending',
text: 'Setting Up Tweet To Raid. Please wait...',
allowOutsideClick: false,
allowEscapeKey: false,
allowEnterKey: false,
showConfirmButton: false,
});
const receipt = await transaction.wait();
console.log('Transaction successful:', receipt);
Swal.close(); // Close the pending alert
Swal.fire({
icon: 'success',
title: 'Transaction Successful',
text: 'Tweet Set Successful',
});
fetchRemianingTweets();
} catch (error) {
console.error('Transaction failed:', error);

Swal.fire({
icon: 'error',
title: 'Transaction Failed',
text: error.reason || error.message,
});
}
};
    







return (
  <Box mb="100px" overflowX="hidden">
    <Grid
      templateColumns={{ base: '1fr', md: '250px 1fr' }}
      gap="20px"
      maxW="100%"
      p={{ base: '10px', md: '20px' }}
    >
      {/* Sidebar Navigation / Hamburger Menu */}
      {isMobile ? (
        <>
          <Box className="hamburger-menu" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </Box>
          {isMobileMenuOpen && (
            <Box
              bg="gray.800"
              p="20px"
              borderRadius="10px"
              color="white"
              position="absolute"
              top="60px"
              left="10px"
              right="10px"
              zIndex="1000"
            >
                <Image 
    src= {shillitary}
    alt="Shillitary Logo" 
    mb="10px" 
    width="100%" // Adjust width as needed
    borderRadius="10px" // Optional: to give the image rounded corners
  />
              <Button
                isFullWidth
                variant="outline"
                colorScheme="pink"
                mb="10px"
                onClick={() => { handleMain('Project Owner'); toggleMobileMenu(); }}
              >
                Project Owners
              </Button>
              <br></br>
              <Button
                isFullWidth
                variant="outline"
                colorScheme="pink"
                mb="10px"
                onClick={() => { handleMain('Shillitary Team'); toggleMobileMenu(); }}
              >
                Shillitary Team
              </Button>
              <br></br>
              <Button
                isFullWidth
                variant="outline"
                colorScheme="pink"
                mb="10px"
                onClick={() => { handleMain('Leaderboard'); toggleMobileMenu(); }}
              >
                Leaderboards
              </Button>
              <br></br>
              <Button
                isFullWidth
                variant="outline"
                colorScheme="pink"
                mb="10px"
                onClick={() => { handleMain('Information'); toggleMobileMenu(); }}
              >
                Information
              </Button>
              <br></br>
              <Button
                isFullWidth
                variant="outline"
                colorScheme="pink"
                mb="10px"
                onClick={() => { handleMain('Profile'); toggleMobileMenu(); }}
              >
                Profile
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Box 
        bg="gray.800" 
        p="20px" 
        borderRadius="10px" 
        color="white" 
        position="sticky" 
        top="20px" 
        zIndex="1000"
      >
        <Image 
    src= {shillitary}
    alt="Shillitary Logo" 
    mb="10px" 
    width="100%" // Adjust width as needed
    borderRadius="10px" // Optional: to give the image rounded corners
  />
        <Button
          isFullWidth
          variant="outline"
          colorScheme="pink"
          mb="10px"
          onClick={() => handleMain('Project Owner')}
        >
          Project Owners
        </Button>
        <Button
          isFullWidth
          variant="outline"
          colorScheme="pink"
          mb="10px"
          onClick={() => handleMain('Shillitary Team')}
        >
          Shillitary Team
        </Button>
        <Button
          isFullWidth
          variant="outline"
          colorScheme="pink"
          mb="10px"
          onClick={() => handleMain('Leaderboard')}
        >
          Leaderboards
        </Button>
        <Button
          isFullWidth
          variant="outline"
          colorScheme="pink"
          mb="10px"
          onClick={() => { handleMain('Information'); }}
        >
          Information
        </Button>
        <Button
          isFullWidth
          variant="outline"
          colorScheme="pink"
          mb="10px"
          onClick={() => { handleMain('Profile'); }}
        >
          User Profile
        </Button>
      </Box>
      
      )}

      {/* Main Content Area */}
      <Box w="100%">
      {showInformation && (
        <Card className='NFTHead' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px" fontSize="25px" fontWeight="bold" p="20px">
          <Box textAlign="center" marginBottom="20px">
    
          </Box>

          <Text className='Heading'>Information</Text>

          <Box display="flex" justifyContent="center" gap="10px" mt="20px" flexWrap="wrap">
            <Button
              className={`toggle-button ${infoValue === 'info' ? 'active' : ''}`}
              onClick={() => handleInfo('info')}
              variant="solid"
              colorScheme="pink"
              w={{ base: '100%', md: 'auto' }}
            >
              What Is The Shillitary
            </Button>
            <Button
              className={`toggle-button ${infoValue === 'projectOwner' ? 'active' : ''}`}
              onClick={() => handleInfo('projectOwner')}
              variant="solid"
              colorScheme="pink"
              w={{ base: '100%', md: 'auto' }}
            >
              For Project Owners
            </Button>
            <Button
              className={`toggle-button ${infoValue === 'Shillitary' ? 'active' : ''}`}
              onClick={() => handleInfo('Shillitary')}
              variant="solid"
              colorScheme="pink"
              w={{ base: '100%', md: 'auto' }}
            >
              For Shillitary Team
            </Button>
          </Box>

          <Box className="content-container" mt="20px">
            {showInfo && (
              <Text className='cardtxt'>
               The Shillitary is a group of like-minded individuals supporting the growth and prosperity of Geeks Ecosystem. The Shillitary Team conducts X & TG raids, and performs organized Shilling operations. Members also get a free education at Geeks Academy where they can study the fine arts of solidity.
<br></br><br></br>
The Shillitary operations can be purchased by outside parties for a fee, 60% of which goes into Buy Backs in Geeks Ecosystem and are rewarded to members as $DeFi Tokens. 
<br></br><br></br>
These DeFi tokens provide members who have been active and demonstrate a high-level of understanding of the digital world with a greater control over Geeks Ecosystem.
              </Text>
            )}
            {showProjectOwnerInfo && (
              <Text className='cardtxt'>
               If you are in need of additional engagement on your X posts, the Shillitary Team is an affordable way of acquiring high-quality engagement with your tweets. You can pay for 1, 3 or 5 tweets and can fill as many as you want to as long as tweets are available. All members will view and like your post, many will retweet and comment. 
<br></br>
<br></br>
Under the Shillitary Team Tab, you can see the total number of tweets the Shillitary has Raided, and how many members there are.
              </Text>
            )}
            {showShillitaryInfo && (
              <Text className='cardtxt'>
               Geeks Ecosystem does not have staking, instead it offers participation-based rewards to all community members via the Shillitary. 
<br></br><br></br>
Members must have a minimum of 100 $Geeks tokens on Base and message @Chef_Code to get a "DogTag" which will give them access to the dApp. 
<br></br><br></br>
Rewards are sent out based on 60% of revenue from the application plus, additional ETH & DeFi rewards from the Treasury as determined by the core team. 
<br></br><br></br>
Your rewards are determined by your % of all total points. Frog NFTs will give you an extra 1x multiplier, each raided tweet is 1 point, and extra points can be acquired by completing Geeks Academy courses. Rewards are sent automatically after completing a series of raids.
<br></br><br></br>
Under the "Shillitary Team" Tab, there will be 5 raids. Click on each one, like the post, comment something, and retweet if you want it on your feed. Please retweet all Geeks Ecosystem Posts. Once you have completed all 5 posts, click "Next Tweets". 
<br></br><br></br>
Under the User Profile tab, you will be able to rank up to change your character design after reaching certain points thresholds, in order to reach Dev / Geek status additional NFTs from Geeks Academy must be acquired from progressing through the Academy courses.    </Text>
            )}
          </Box>
        </Card>
      )}

{showProjectOwner && (
  <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold" p="20px" mt="20px">
    {!address ? (
      <Text className='cardtxt'>User Not Found<br />Please connect Your Wallet To Begin</Text>
    ) : (
      <>
        <Box className="card-header10" textAlign="center" marginBottom="20px" w="100%">
        <Text className='Heading'>
           Buy Raids 
          </Text>
          <Text className='baseHead'>
            Todays Remaining Open Slots<br />{remmainingTweets} Spots Open
          </Text>
          <Text className='baseHead'>
            Slots Reset On {showReset}
          </Text>

          <Text marginTop="15px" className='small'>Payment through dApp in Base ETH Only<br></br> Contact @Chef_Code to get Tweets Manually Added
      </Text>
        </Box>
        <Box className="toggle-container" w="100%">
          {renderTweetOptions()}
        </Box>
      </>
    )}
  </Card>
)}

        {showShillitary && (
          <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold" p="20px" mt="20px">
            {!address ? (
              <Text className='cardtxt'>User Not Found<br />Please connect Your Wallet To Begin</Text>
            ) : (
              <>
            
    <Grid 
  templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} 
  gap={4} 
  textAlign="center" 
  mt="20px">
            <Text className="popuptxt">Global Users<br />{showTotalUser}</Text>
            <Text className="popuptxt">Global Raided<br />{showTotalRaid}</Text>
            </Grid>
                <TweetsToRaid tweets={[showRaidable1, showRaidable2, showRaidable3, showRaidable4, showRaidable5]} />
              </>
            )}
          </Card>
        )}

{showProfile && (
  <Card
    className='NFTHead'
    textAlign="center"
    border="2px"
    borderColor='blue.50'
    borderRadius="30px"
    fontSize="25px"
    fontWeight="bold"
    p="20px"
  >
    <Box textAlign="center" marginBottom="20px">
      {!address ? (
        <Text className='cardtxt'>User Not Found<br />Please connect Your Wallet To Begin</Text>
      ) : (
        <>
          <Text className='Heading'>Profile For {showUserName}</Text>
        </>
      )}
    </Box>

    {/* Layout with text wrapping the left side of the image */}
    <Grid
      templateColumns={{ base: '1fr', md: '2fr auto' }} // 2 columns: text on the left, image on the right
      gap={4}
      textAlign="center"
      alignItems="center"
      mt="20px"
    >
      {/* Left text wrapping around the image */}
      <Box className='infoBox'>
        <Text className="popuptxtinfohead">Shillitary Rank</Text>
        <Text className="popuptxtinfo">{showUserRank}</Text>

        <Text className="popuptxtinfohead">Tweets Raided</Text>
        <Text className="popuptxtinfo">{showUserRaided} Tweets</Text>

        <Text className="popuptxtinfohead">Pending ETH</Text>
        <Text className="popuptxtinfo">{showUserPeth} ETH</Text>

        <Text className="popuptxtinfohead">Eth Earned</Text>
        <Text className="popuptxtinfo">{showUserTotalEth} ETH</Text>

        <Text className="popuptxtinfohead">Shillitary Points</Text>
        <Text className="popuptxtinfo">{showUserPoints}</Text>
        
        <Text className="popuptxtinfohead">Pending DeFi</Text>
        <Text className="popuptxtinfo">{showUserpdefi} DeFi</Text>

        <Text className="popuptxtinfohead">DeFi Earned</Text>
        <Text className="popuptxtinfo">{showUserTotalDefi} DeFi</Text>

        <Text className="popuptxtinfohead">Points Away From Rank-Up</Text>
        <Text className="popuptxtinfo">{showTillRank} Points</Text>
        
      </Box>

      {/* Right centered image */}
      <Box textAlign="center">
        <Image
          src={showImage} // Replace 'showImage' with your actual image source
          alt="Profile Image"
          borderRadius="full"
          boxSize={{ base: "150px", md: "350px" }} // Adjust size for responsiveness
          mx="auto"
        />
      </Box>
    </Grid>

    {showTillRank <= 0 && (
      <Button
        mt="20px"
        colorScheme="pink"
        onClick={() => UpgradeRank()}
      >
        Upgrade Rank
      </Button>
    )}
  </Card>
)}




{showLeaderboard && (
  <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="20px" mt="20px">
    <Card className="card-header10">
      <Box as="h2" sx={{ fontSize: '20px', textAlign: 'center', color: '#9fff40', margin: '5px', fontWeight: '800' }}>
        Raided Leaderboard
      </Box>
      {leaderboardData.map((entry, index) => (
        <Box
          as="div"
          sx={{ color: '#eff1a4', fontSize: '15px', textAlign: 'center', margin: '5px', fontWeight: '600' }}
          key={index}
        >
          {index + 1}: {entry.username}: {entry.tweetsRaided} Raided
        </Box>
      ))}
    </Card>
    <Card className="card-header10">
      <Box as="h2" sx={{ fontSize: '20px', textAlign: 'center', color: '#9fff40', margin: '5px', fontWeight: '800' }}>
        Points Leaderboard
      </Box>
      {pointsleaderboardData.map((entry, index) => (
        <Box
          as="div"
          sx={{ color: '#eff1a4', fontSize: '15px', textAlign: 'center', margin: '5px', fontWeight: '600'}}
          key={index}
        >
          {index + 1}: {entry.username}: {entry.points} Points
        </Box>
      ))}
    </Card>
  </Grid>
)}


      </Box>
    </Grid>
  </Box>
);
};

export default NFTHead;






